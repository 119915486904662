import { graphql } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import {
  Button,
  CurveBox,
  EyeBrowText,
  HeroHeadline,
  Paragraph,
  SubHeadline,
} from '../components/atoms'
import { ScrollSVGAnimator, SVGDropper } from '../components/atoms/'
import { Layout } from '../components/layouts/Layout'
import { ConstrainedWidthContainer } from '../components/molecules'
import { GetInTouch, SEO, TravelGetMore } from '../components/organisms'
import SellersIcon from '../images/DoMoreSellers.svg'
import DotsSvg from '../images/dots.svg'
import BookingPalLogo from '../images/Logo_bookingPal.svg'
import ChoiceLogo from '../images/Logo_Choice.svg'
import EscapiaLogo from '../images/Logo_Escapia.svg'
import KigoLogo from '../images/Logo_Kigo.svg'
import LiveRezLogo from '../images/Logo_LiveRez.svg'
import StayAlfredLogo from '../images/Logo_StayAlfred.svg'
import StreamlineLogo from '../images/Logo_streamline.svg'
import TurnkeyLogo from '../images/Logo_Turnkey.svg'
import VtripsLogo from '../images/Logo_vtrips.svg'
import WyndhamLogo from '../images/Logo_Wyndham.svg'
import HeroSVG from '../images/lottie/Travel_Graphic_Hero.json'
import SaveTimeMoneySVG from '../images/lottie/Travel_Graphic_SaveTimeMoney.json'
import UnlockRecurringSVG from '../images/lottie/Travel_Graphic_UnlockRecurring.json'
import FraudIcon from '../images/PreventFraud.svg'
import HomeHeroImageMobile from '../images/Travel_Graphic_Hero_Mobile.svg'
import SaveTimeImageMobile from '../images/Travel_Graphic_SaveTimeMoney_Mobile.svg'
import UnlockImageMobile from '../images/Travel_Graphic_UnlockRecurring_Mobile.svg'
import TravelImage from '../images/travel_payments.png'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const HomeHeroImageContainer = styled(Box)`
  background: no-repeat center url("${HomeHeroImageMobile}");
  background-size: contain;
  background-position: center;
  height: 20rem;
  display: block;

  ${Mq.lg} {
    display: none;
  }
`
const HomeHeroSVGContainer = styled(Box)`
  display: none;

  ${Mq.lg} {
    display: block;
    transform: translateX(5%) translateY(-8%);
  }
`

const CurveBoxTop = styled(CurveBox)`
  height: 50px;
  top: 0;
  transform: rotate(180deg);
`

const CurveBoxBottom = styled(CurveBox)`
  height: 50px;
  bottom: 0;
`

const SectionImageContainer = styled(Box)`
  display: block; 
  background: no-repeat center url("${props => props.imageUrlMobile}");
  background-size: contain;
  min-height: 15rem;
  order: -1;

  ${Mq.sm} {
    display: block;
    min-height: 20rem;
    order: 0;
  }

  ${Mq.md} {
    display: none;
    min-height: 30rem;
    order: 0;
  }
`

const SectionSVGContainer = styled(Box)`
  display: none;
  min-height: 15rem;
  order: -1;

  ${Mq.md} {
    display: block;
    min-height: 30rem;
    order: 0;
  }
`

const SectionNewsImageContainer = styled(Box)`
  background: no-repeat center url("${props => props.imageUrlMobile}");
  background-size: contain;
  min-height: 15rem;
  order: -1;

  ${Mq.sm} {
    min-height: 20rem;
    order: 0;
  }

  ${Mq.md} {
    background: no-repeat center url("${props => props.imageUrl}");
    min-height: 30rem;
    order: 0;
  }
`

const ColoredBackgroundContainer = styled(Box)`
  position: relative;
  background-color: ${props => props.theme.colors.lightBlue};
`

const CustomerHeadlineContainer = styled(Box)`
  ${Mq.sm} {
    text-align: center;
  }
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  top: 2rem;
  right: -7rem;
  z-index: -1;
  display: none;

  ${Mq.xl} {
    display: block;
  }
`

const TravelPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.contentstackTravelPage.title}
      description={data.contentstackTravelPage.seo_description}
    />

    <ConstrainedWidthContainer>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Box width={[1, 1, 1, 5 / 12]} order={[1, 1, 1, 0]}>
          <Box pt={[0, 5]} pb={[0, 0, 5]}>
            <HeroHeadline>
              {data.contentstackTravelPage.hero_headline}
            </HeroHeadline>
            <Paragraph>
              {data.contentstackTravelPage.hero_description}
            </Paragraph>
          </Box>
        </Box>
        <Box width={[1, 1, 1, 7 / 12]} order={[0, 0, 0, 1]}>
          <HomeHeroImageContainer />
          <HomeHeroSVGContainer>
            <SVGDropper jsonObject={HeroSVG} loop={false} autoplay={true} />
          </HomeHeroSVGContainer>
        </Box>
      </Flex>
    </ConstrainedWidthContainer>

    <TravelGetMore />

    <ColoredBackgroundContainer py={5} mb={4}>
      <CurveBoxTop />
      <ConstrainedWidthContainer py={5}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Box width={[1, 1, 5 / 12]} mb={[3, 3, 0]}>
            <img src={SellersIcon} alt="Sellers Icon" />
            <SubHeadline>
              {data.contentstackTravelPage.do_more_headline}
            </SubHeadline>
            <Paragraph>
              {data.contentstackTravelPage.do_more_description}
            </Paragraph>
          </Box>

          <Box width={[1, 1, 5 / 12]}>
            <img src={FraudIcon} alt="Prevent Fraud Icon" />
            <SubHeadline>
              {data.contentstackTravelPage.fraud_headline}
            </SubHeadline>
            <Paragraph>
              {data.contentstackTravelPage.fraud_description}
            </Paragraph>
          </Box>
        </Flex>
      </ConstrainedWidthContainer>
      <CurveBoxBottom />
    </ColoredBackgroundContainer>

    <ConstrainedWidthContainer>
      <Flex flexWrap="wrap" alignItems="center">
        <Box width={[1, 5 / 12]} mb={[3, 0]}>
          <SubHeadline>
            {data.contentstackTravelPage.save_time_headline}
          </SubHeadline>
          <Paragraph>
            {data.contentstackTravelPage.save_time_description}
          </Paragraph>
        </Box>

        <SectionImageContainer
          width={[1, 7 / 12]}
          imageUrlMobile={SaveTimeImageMobile}
        />
        <SectionSVGContainer width={[1, 7 / 12]}>
          <ScrollSVGAnimator
            thresholdPercent={0.4}
            jsonObject={SaveTimeMoneySVG}
          />
        </SectionSVGContainer>
      </Flex>
    </ConstrainedWidthContainer>

    <ColoredBackgroundContainer py={5} mb={5}>
      <CurveBoxTop />
      <ConstrainedWidthContainer>
        <Flex flexWrap="wrap" alignItems="center">
          <SectionImageContainer
            width={[1, 7 / 12]}
            imageUrlMobile={UnlockImageMobile}
          />
          <SectionSVGContainer width={[1, 7 / 12]}>
            <ScrollSVGAnimator
              thresholdPercent={0.45}
              jsonObject={UnlockRecurringSVG}
            />
          </SectionSVGContainer>
          <Box width={[1, 5 / 12]} mb={[3, 0]}>
            <SubHeadline>
              {data.contentstackTravelPage.unlock_opportunities_headline}
            </SubHeadline>
            <Paragraph>
              {data.contentstackTravelPage.unlock_opportunities_description}
            </Paragraph>
          </Box>
        </Flex>
      </ConstrainedWidthContainer>
      <CurveBoxBottom />
    </ColoredBackgroundContainer>

    <ConstrainedWidthContainer>
      <Flex
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Box width={[1, 5 / 12]} mb={[3, 0]}>
          <Box mb={3}>
            <EyeBrowText>
              {data.contentstackTravelPage.in_the_news_eyebrow}
            </EyeBrowText>
          </Box>
          <Dots />
          <SubHeadline>
            {data.contentstackTravelPage.in_the_news_headline}
          </SubHeadline>
          <Paragraph>
            {data.contentstackTravelPage.in_the_news_description}
          </Paragraph>
          <a
            href={data.contentstackTravelPage.in_the_news_button_url}
            target="_blank"
            rel="noreferrer"
          >
            <Button width={[1, 'auto']}>
              {data.contentstackTravelPage.in_the_news_button_text}
            </Button>
          </a>
        </Box>

        <SectionNewsImageContainer
          width={[1, 6 / 12]}
          imageUrl={TravelImage}
          imageUrlMobile={TravelImage}
        />
      </Flex>

      <CustomerHeadlineContainer>
        <SubHeadline>
          {data.contentstackTravelPage.customer_stories_headline}
        </SubHeadline>
      </CustomerHeadlineContainer>

      <Flex
        flexWrap="wrap"
        textAlign="center"
        alignItems="center"
        justifyContent={['center', 'center', 'space-between']}
        my={[2, 5]}
      >
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={KigoLogo} alt="Kigo Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={ChoiceLogo} alt="Choice Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={WyndhamLogo} alt="Wyndham Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={TurnkeyLogo} alt="Turnkey Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={StayAlfredLogo} alt="Stay Alfred Logo" />
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        textAlign="center"
        alignItems="center"
        justifyContent={['center', 'center', 'space-between']}
        mb={[2, 5]}
      >
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={VtripsLogo} alt="Vtrips Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={StreamlineLogo} alt="Streamline Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={EscapiaLogo} alt="Escapia Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={LiveRezLogo} alt="Liverez Logo" />
        </Box>
        <Box flex={['0 0 auto', '1']} width={[1, 'auto']} mb={[3, 0]}>
          <img src={BookingPalLogo} alt="BookingPal Logo" />
        </Box>
      </Flex>

      <Flex flexWrap="wrap" justifyContent="center">
        <Box mb={[3, 0]} width={[1, 6 / 12]} textAlign="right" pr={[0, 2]}>
          <a
            target="_blank"
            rel="noreferrer"
            href={data.contentstackTravelPage.customer_story_1_file.url}
          >
            <Button width={[1, 'auto']}>
              {data.contentstackTravelPage.customer_story_1_link_text}
            </Button>
          </a>
        </Box>

        <Box width={[1, 6 / 12]} pl={[0, 2]}>
          <a
            target="_blank"
            rel="noreferrer"
            href={data.contentstackTravelPage.customer_story_2_file.url}
          >
            <Button width={[1, 'auto']}>
              {data.contentstackTravelPage.customer_story_2_link_text}
            </Button>
          </a>
        </Box>
      </Flex>
    </ConstrainedWidthContainer>

    <GetInTouch />
  </Layout>
)

export default TravelPage

export const query = graphql`
  query {
    contentstackTravelPage {
      title
      seo_description
      hero_headline
      hero_description
      do_more_headline
      do_more_description
      fraud_headline
      fraud_description
      save_time_headline
      save_time_description
      unlock_opportunities_headline
      unlock_opportunities_description
      in_the_news_eyebrow
      in_the_news_headline
      in_the_news_button_text
      in_the_news_description
      in_the_news_button_url
      customer_stories_headline
      customer_story_1_link_text
      customer_story_1_file {
        url
      }
      customer_story_2_link_text
      customer_story_2_file {
        url
      }
    }
  }
`
